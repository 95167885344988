import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection({ bgimg1, bgimg2, bgimg3, bgimg4 }) {
  const { rpdata } = useContext(GlobalDataContext);

  const bgslider = [
    bgimg1,
    bgimg2,
    bgimg3,
    bgimg4,
  ]
  return (
    <>
      <div className="pic-wrapper">
        {
          bgslider?.map((item, index) => (
            <figure
              key={index}
              className={`pic-${index + 1}`}
              style={{ background: `url("${bgslider[index]}")` }}
            ></figure>
          ))
        }

        <img
          src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FSlice%20Top.png?alt=media&token=2de29ecf-9425-4778-9606-0a59172af59d"}
          alt='no found'
          loading='lazy'
          className='absolute -top-3 left-0 w-[100%] md:h-[6%] h-[10%] z-[4]'
        />
        <img
          src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FSlice%20Buttom_1.png?alt=media&token=1c5e029f-a661-48e2-90b2-62485f08a20b"}
          alt='no found'
          loading='lazy'
          className='absolute -bottom-1 left-0 w-[100%] h-[9%] md:h-[120px] z-[4]'
        />
        <div className="z-[4] relative w-4/5 mx-auto pt-[270px] pb-[100px] md:pb-[150px] md:pt-[380px] ">
          <div className="w-full flex flex-col justify-center items-center">
            <p className="text-white text-center md:text-start titleHero md:text-[28px] text-[18px]">{rpdata.dbSlogan?.[0].slogan}!</p>
            <img className="w-[50px] h-[20px]"
              src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Restaurante%2FMY.png?alt=media&token=f1c4c328-7180-4876-88ee-371d43c13aaf"
              alt="bite" />
            <h1 className="text-white md:text-[80px] text-[40px] text-center ">
              {rpdata?.dbSlogan?.[4].slogan}
            </h1>
            <p className="text-white text-center md:flex hidden">{rpdata?.dbValues?.[1].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent btnStyle={'six'} btnUrl={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} btnName={'EXPLORE MENU'} />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
